<template>
  <div class="drainage-link">
    <div class="wx-logo">
      <img :src="qrcode_url">
      <div class="mylabel">
        长按识别二维码添加好友
      </div>
    </div>
    
    <div class="footer">
      <div class="logo">
        <img src="@/static/images/logo.png">
        微裂变
      </div>
      <div class="text">
        最好用的企业微信管理工具
      </div>
    </div>
  </div>
</template>

<script>
import {redEnvelopeLinkApi} from "@/api/redEnvelope"; 

export default {
  data() {
    return {
      qrcode_url:'',
      id: ''
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.setTitle() 
  },
  methods: {
    setTitle() {
      redEnvelopeLinkApi({
        id: this.id
      }).then(res => { 
          document.title = res.data.active_name  
          this.qrcode_url=res.data.qrcode_url
      })
    }
  }
}
</script>

<style lang="less" scoped>
.drainage-link {
  height: 100vh;
  display: flex;
  background-color: #e1e2e3;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.wx-logo {
  width: 70%;
  margin: 0 auto; 
  background-color: #fff;

  img {
    width: 100%;
  }
}
.mylabel{
  width: 100%;
  height: 48px;  
  color: #646667;
  font-size: 16px;
  font-weight: bold;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;  
}
.btn {
  width: 210px;
  height: 48px;
  background: #1584ff;
  border-radius: 2px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: 17px;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(0, 0, 0, .25);

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 26px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }

  .text {
    margin-top: 6px;
    font-size: 13px;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgba(0, 0, 0, .25);
  margin-top: 40rpx;
}

.footer .text {
  margin-top: 18rpx;
  font-size: 13px;
}

.footer .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 26rpx;
}

.footer .logo image {
  width: 46rpx;
  height: 46rpx;
  margin-right: 8rpx;
}
</style>
